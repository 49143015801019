.block__wrapper {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .block__container {
    display: flex;
    max-width: 600px;
    flex-direction: column;

    padding-top: 6rem;
    padding-bottom: 2rem;
    text-align: center;
    @media screen and (max-width: 600px) {
      padding-top: 3rem;
    }
  
    h1 {
      align-items: center;
      color: #013459;
      font-size: 42px;
    font-weight: 600;
    line-height: 50px;
    }
  
    h3 {
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      color: #37b7ed;
      font-size: 30px;
      font-weight: 600;
    }
  
    p {
      align-items: center;
      margin-bottom: 3rem;
    }
  
    .block__cards__column {
      .block__card__item {
        display: flex;
        margin-bottom: 3rem;
        @media screen and (max-width: 480px) {
          margin-bottom: 2rem;
        }
        .block__card__img {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          img {
            width: 70px;
            @media screen and (max-width: 480px) {
              width: 50px;
            }
          }
        }
  
        .block__card__info {
          text-align: start;
          h3 {
            margin-top: 0;
            margin-bottom: 0.8rem;
            color: #7ecef3;
            font-size: 23px;
            font-weight: 400;
            text-align: left;
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
        }
      }
    }
  }
}

