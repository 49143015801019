html {
  font-size: 16px !important;
  box-sizing: border-box;
  @media (min-width: $screen-md) {
    height: 100%;
  }
  body {
    color: $body-color;
    font-weight: 400;
    font-family: $font-family !important;
    font-size: 16px !important;
    line-height: 20px;
  }
  a {
    color: $primary500;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
