@import '../../scss/site-variables';

:global {
  .DateRangePicker {
    z-index: 999;
  }

  .DateInput {
    display: none;
  }

  .SingleDatePickerInput__withBorder {
    display: block;
  }



  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: $primary500;
    border-radius: 50%;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    display: none;
    border-right: 33px solid $primary500;
  }

  .CalendarDay__default {
    border: 0;
  }

}

  .pickupdropoff{
    margin-top: 3%;
  }
.label {
  display: block;
  color: $primary900;
  font-size: 14px;
  font-weight: 400;
}

.button {
  width: 100%;
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid #e0e0e0;
  background: transparent;
  border-radius: 5px;
  color: $primary900;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;

  &:focus {
    border-color:$border-blue;
    color:$grey900;
  }
}

.button___error {
  border-color: $site-red;
}

.icon {
  overflow: visible !important;
  padding-top: 8px;
  margin: 0 8px;
  font-size: 24px;
}

.datepicker_input {
  position: absolute;
  top: 0;
}

.caret {
  border-width: 5px;
  margin: 14px 5px 0 20px;
  color: $primary900;
  float: right;
}

.datepicker__errorMessage {
  color: $site-red;
  font-size: 12px;
  line-height: 15px;
}
