@import '../../scss/site-variables';

.content-container {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    margin-bottom: 50px;
  }
}

.full-width-container {
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent !important;
  @media (min-width: 1024px) {
    margin-bottom: 50px;
  }
}

.top_container {
  height: 100vh;
  margin-top: 115px;
  background: url('../../assets/washmix-process/Background.png');
  object-fit: contain;
  p {
    width: 100%;
    max-width: 880px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
}
.top_heading {
  color: #fff;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
}

.process_container {
  height: 500px;
  div {
    width: 100%;
    height: 400px;
    @media (min-width: 1024px) {
      width: 60%;
    }
    img {
      object-fit: contain;
    }
  }
}

.bg_color_container {
  background: #003459;
}
.home_heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 55px;
}
.bg_color_para {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.card_heading {
  color: #39b6ed;
  font-size: 22px;
  line-height: 22px;
}
.card_para {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.image_group_heading {
  color: #003459;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
}
.image_group_para {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.vector_postion_right {
  position: absolute;
  right: 0 !important;
  bottom: -100px !important;
}
.vector_postion_left {
  position: absolute;
  bottom: -70px !important;
  left: 0;
}
.bg_container {
  margin-top: -70px;
  background: url('../../assets/washmix-process/bg_process.png');
}
