@import '../../scss/site-variables';

.container {
  overflow: auto;
}

.table {
  td {
    padding: 8px 16px;
    border: 1px solid $grey400;
  }
}

.table___seasons {
  text-transform: uppercase;
  td:first-child {
    font-weight: 700;
  }
  td:nth-child(2) {
    text-align: center;
  }
  td:last-child {
    color: $accent500;
    font-weight: 700;
  }
}

.table___costs {
  min-width: 710px;
  font-weight: 500;
  text-align: center;
  tr:last-child {
    vertical-align: baseline;
  }
  thead {
    background-color: $grey300;
  }
  small {
    font-weight: normal;
  }
}
