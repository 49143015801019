@import '../../scss/site-variables';

.container {
  margin-top: 125px;
  background-color: $grey100;
}

.bg {
  --border-bottom: 17.7%;
  --border-top: 18.6%;
  height: 429px;
  margin-top: -116px;
  background-image: url('../../assets/pending-request.svg'),
    linear-gradient(
      0deg,
      $grey100 0%,
      $grey100 var(--border-bottom),
      $primary900 var(--border-bottom),
      $primary900 var(--border-top),
      $primary300 var(--border-top),
      $primary300 100%
    );
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 530px, 100%;
  @media (max-width: 767px) {
    --border-bottom: 14.9%;
    --border-top: 15.7%;
    height: 255px;
    margin-top: -84px;
    background-size: 265px, 100%;
  }
}

.content {
  width: 368px;
  padding-bottom: 70px;
  margin: 22px auto 0;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
}

.message {
  margin: 0 22px 24px;
}

.header {
  margin: 0 0 8px;
}

.desc {
  margin: 0 0 36px;
}
.bold_desc {
  margin: 0 0 36px;
  font-weight: bold;
  text-decoration: underline;
}
.pickup_date_heading{
  margin-right:20px;
  font-weight: bold;
  text-decoration: underline;
}

.desc2 {
  margin: 0;
}
