.checkout_pannel {
  padding: 24px 0 40px;

  .billing_info {
    margin-top: 45px;
  }

  .shadow_pannel {
    padding: 36px 46px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 $shadow-color;

    @media (max-width: $screen-xs) {
      padding: 16px;
      box-shadow: none;
    }

    .checkboxes {
      div {
        margin-bottom: 20px;
      }
    }

    .error {
      font-size: 12px;
      line-height: 15px;
    }

    .blue_color_txt {
      color: $primary500;
    }

    h1 {
      margin: 0 0 24px;
      color: $primary900;
      font-size: 20px;
      line-height: 1.4;
    }

    h2 {
      margin: 0;
      color: $primary900;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;

      span {
        color: $primary500;
      }
    }

    div + div {
      h2 {
        margin: 14px 0 0;
      }
    }

    p {
      margin: 0;
      color: $grey800;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
    }

    .half {
      display: inline-block;
      width: calc(50% - 8px);
      vertical-align: top;
    }

    .half + .half {
      margin-left: 16px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      position: relative;
    }

    .total_pannel {
      width: 100%;
      padding-top: 8px;
      border-top: 1px solid $grey400;
      margin-top: 30px;
      float: left;

      div {
        width: 100%;
        margin-top: 20px;
        float: left;

        &.total_row {
          margin-top: 30px;
          margin-bottom: 20px;

          strong {
            color: $primary900;
            font-size: 18px;
            letter-spacing: 1px;
          }
        }
      }

      h2 {
        margin: 0;
        float: left;
      }

      p {
        float: right;
        text-align: right;

        &.text-center {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
    }

    .billing-error {
      display: block;
      margin-top: -15px;
      margin-bottom: 5px;
    }
  }

  .place_order_txt {
    display: inline-block;
    margin: 16px;

    p {
      width: 100%;
      margin: auto;
      clear: both;
      color: $grey600;
      font-size: $size-13;

      strong {
        color: $grey900;
      }
    }
  }
}

@media (min-width: $screen-xs) {
  .total_pannel {
    h2 {
      width: calc(100% - 100px);
    }
  }
}

@media (max-width: $screen-xs) {
  .checkout_pannel {
    .shadow_pannel {
      margin-bottom: 20px;

      .half {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }

      .half + .half {
        margin-left: 0;
      }
    }

    .container {
      padding: 0;

      .col-lg-6 {
        padding: 0;

        .shadow_mob_0 {
          margin-bottom: 0;
          box-shadow: 0 0 0 0 transparent;

          li {
            &:last-child {
              &::after {
                z-index: 99;
                right: 0;
                bottom: -60px;
                left: 0;
                width: 100%;
                height: 1px;
                margin: auto;
                background-color: $grey400;
                content: '';
              }
            }
          }
        }

        .shadow_pannel {
          border-radius: 0;
        }
      }
    }
  }
}
