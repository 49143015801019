
.packages-delivery {
  padding: 24px 0 40px;
  background-color: $grey100;

  .half {
    @media (min-width: $screen-md) {
      width: 50%;
    }
  }

  .hideOnMobile{
    @media (max-width: $screen-md) {
      display: none;
    }
  }

  .packages-delivery-pannel {
    width: 94%;
    max-width: 800px;
    padding: 35px 50px;
    margin: 0 auto 40px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 $shadow-color;

    h1 {
      margin: 0 0 20px;
      color: $primary900;
      font-size: 24px;
      line-height: 1.33;
    }

    h2 {
      margin-bottom: 5px;
      color: $primary900;
      font-size: 18px;
      line-height: 22px;
    }

    p {
      margin: 0 0 20px;
      color: $grey800;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.71;

      strong {
        color: $primary900;
        font-weight: 500;
      }
    }

    .form-group {
      position: relative;
    }
  }

  .top_bodr {
    position: relative;
    z-index: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0;
    border-top: 1px solid $grey400;
    background-color: $white;
    text-align: center;
    word-spacing: -5px;

    button + button {
      margin-left: 80px;

      @media (max-width: $screen-xs) {
        margin-left: 50px;
      }
    }
  }
}
