@import '../../scss/site-variables';

.input {
  position: relative;
}

.input__field {
  display: block;
  width: 100%;
  color: $primary900;
  font-size: 16px;
}

.input_success {
  color: $accent500;
}

.input__field {
  height: 48px;
  padding: 0 2rem 0 1rem;
  border: 1px solid #e0e0e0;
  appearance: none;
  background-color: $white;
  border-radius: 6px;
  box-shadow: none;
  font-weight: 400;
  line-height: 1.5;
}

.input__field_stripe {
  padding: 0.9rem 2rem 0 1rem;
}

.input__field_error {
  border-color: $site-red;
}

.input__field_success {
  border-color: $accent500;
}

.input__field:focus {
  border-color: $primary500;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.input__label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.input__oval {
  position: absolute;
  top: 50px;
  right: 24px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: $primary500;
  border-radius: 100%;
}

.input__loading {
  position: absolute;
  top: 40px;
  right: 15px;
  display: block;
  width: 25px;
  height: 25px;
  animation: loadingAnimation 720ms steps(8, start) infinite reverse;
  background-image: url('../../assets/loading.svg');
  background-size: cover;
  border-radius: 100%;
}

@keyframes loadingAnimation {
  to {
    transform: rotate(360deg);
  }
}

.input__oval_error {
  background-color: $site-red;
}

.input__errorMessage {
  color: $site-red;
  font-size: 12px;
  line-height: 15px;
}

.input__dropdownBase {
  position: relative;
}

.input__dropdown {
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
  left: 0;
  max-height: 25em;
  padding: 0;
  border: 1px solid #e0e0e0;
  margin-top: -1px;
  background: #fff;
  border-radius: 6px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.input__dropdown_hidden {
  overflow: hidden;
  max-height: 0;
  border-width: 0;
}

.input__dropdown_item {
  padding: 15px 16px;
  cursor: pointer;
  font-size: 14px;
}

.input__dropdown_item:hover,
.input__dropdown_item:focus {
  background-color: #39b6ed33;
}

.input__dropdown_item_active {
  background: $primary500;
  color: $white;
}

.input__dropdown_item_active:hover,
.input__dropdown_item_active:focus {
  background: $primary400;
}
