.badge-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2% 10%;
    border-top: 1px solid #e5e5e5;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}



.badge-main{
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    
        
    }
}

.badgeimageMain{
    height: 120px;
}
.subtag{
    color: #8c8c8c80;
    font-size: small;
}
.badge-section{
    padding-bottom: 50px;
    .badge-container-new{
        border-top: 0;
        .badge-main{
            min-width: 330px;
            max-width: 370px;
            height: 210px;
            align-items: flex-start;
            padding-right: 10px;
            padding-left: 5px;
            margin: 15px;
            box-shadow: 0 0 70px 0 #bfdfed;
            box-shadow: 0 0 70px 0 #bfdfed;
            box-shadow: 0 0 70px 0 #bfdfed;
            img{
                height: 85px;
                margin-left: 5px;
            }
            p{
                padding-right: 5px;
                padding-left: 25px;
            }
            .tagline{
                color: #39b6ed;
                font-size: 22px;
                font-weight: 600;
                text-align: start;
            }
            .subtag{
                color: #003459;
                font-size: 16px;
                text-align: start;
            }
        }
    }
}
