@import '../../scss/site-variables';

.block {
  padding: 0 20px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__header {
  display: flex;
  justify-content: flex-end;
}

.modal__header___hasTitle {
  justify-content: space-between;
}

.modal__close {
  cursor: pointer;
  font-size: 48px;
  transition: transform 0.1s linear;
  &:hover {
    transform: scale(1.2);
  }
  @media (max-width: 767px) {
    top: 28px;
    right: 10px;
  }
}

.spaceXL {
  margin: 24px 0;
}

.spaceM {
  margin: 16px 0;
}

.spaceXS {
  margin: 8px 0;
}

.icon {
  margin-right: 10px;
  color: $grey600;
  font-size: 24px;
}

.card {
  display: flex;
  justify-content: flex-start;
}

.plusIcon {
  display: inline-block;
  width: 24px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
}

.button {
  margin-top: 40px;
}
