@import '../../scss/site-variables';

.password {
  display: grid;
  padding: 0;
  margin-top: 85px;
  background-color: #fafafa;
  place-items: center;

  @media (min-width: 768px) {
    padding: 44px 32px 80px;
  }

  @media (min-width: 1024px) {
    padding: 120px 32px 80px;
  }
}

.password__wrapper {
  width: 100%;
  max-width: 528px;
  padding: 16px;
  background-color: $white;

  @media (min-width: 768px) {
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 1024px) {
    max-width: 588px;
    padding: 48px 60px 40px;
  }
}

.password__title {
  margin: 0 0 12px;
  color: $primary900;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.password__description {
  margin-bottom: 22px;
  color: #757575;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }

  strong, a {
    font-weight: 500;
  }
}
