@import '../../scss/site-variables';

.checkbox {
  position: relative;
  color: $primary900;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.checkbox_error {
  color: $site-red;
}

.checkbox__field {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  opacity: 0;
}

.checkbox__label {
  display: block;
  padding: 0 0 0 28px;
  margin: 0;
  font-weight: inherit;
  letter-spacing: 0.51px;
  user-select: none;
}

.checkbox__label::before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  content: '' !important // for overriding django stiles;
}

.checkbox__label::after {
  position: absolute;
  top: 6px;
  left: 3px;
  width: 10px;
  height: 8px;
  background-image: url('../../assets/checkmark.svg');
  background-repeat: no-repeat;
  content: '';
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox__field:checked + .checkbox__label::before,
.checkbox__field:hover + .checkbox__label::before {
  border-color: #f5a623;
}

.checkbox__field:checked + .checkbox__label::after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__label::before {
  box-shadow: 0 0 0 3px #ffbf47;
  outline: 3px solid transparent;
}

.checkbox__field_error + .checkbox__label::before {
  border-color: $site-red;
}

.checkbox__field:focus {
  border-color: $primary500;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.checkbox__errorMessage {
  color: $site-red;
}
