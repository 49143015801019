// Typography Size
h1{
	font-size: $size-50;
}
h2{
	font-size: $size-26;
}
h3{
	font-size: $size-24;
}
h4{
	font-size: $size-20;
}
h5{
	font-size: $size-18;
}
h6{
	font-size: $size-16;
}
