@import '../../scss/site-variables';

.faqs-page {
  margin-top: 170px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  .close {
    opacity: 1 !important;
  }

  h2 {
    color: $primary900;
    font-size: 36px;
  }

  .page-description {
    padding: 26px 0 60px;
    color: $grey800;
    line-height: 24px;

    a {
      color: $primary900;
      font-weight: 500;
    }
  }

  .page-content {
    @media (min-width: 1024px) {
      display: grid;
      margin: 0 -15px;
      grid-template-columns: 300px 1fr;
    }
  }

  .section-headings {
    ul {
      padding: 27px 12px;
      border: solid 1px $grey300;
      list-style: none;

      li {
        padding: 0 10px;
        margin-bottom: 20px;
        color: $primary900;
        cursor: pointer;
        font-size: 14px;

        &.active {
          padding-left: 8px;
          border-left: solid 2px $primary900;
        }

        &.active,
        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .section-content {
    @media (min-width: 1024px) {
      padding-left: 45px;
    }
    .wm-collapse,
    .wm-collapse.question {
      .heading {
        position: relative;
        display: flex;
        width: 100%;
        // height: 90px;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: solid 1px $grey300;
        cursor: pointer;

        &.is-open {
          border-bottom-color: $white;
        }

        .title {
          display: block;
          width: 85%;
          color: $primary900;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .description {
        color: $primary900;
        font-size: 14px;
        line-height: 24px;

        &.is-open {
          padding-bottom: 15px;
          border-bottom: solid 0.5px $grey300;
        }

        &.is-close {
          font-weight: normal;
        }
      }
    }

    .section-info {
      margin: 20px 0;
    }
  }

  @media (max-width: 1023px) {
    .list {
      padding: 0;
      list-style: none;

      .list-item {
        .list-item {
          padding-left: 10px;
        }

        .wm-collapse {
          border-bottom: 0;
        }

        .wm-collapse.section-item {
          > .heading {
            display: flex;
            height: 60px;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;

            &.is-close {
              margin-bottom: 15px;
            }

            .title {
              display: block;
              width: 85%;
              color: $primary900;
              font-size: 14px;
              font-weight: 500;
            }

            &.is-open {
              background-color: $primary900;

              .title {
                color: $white;
              }
            }

            &.is-close {
              border: solid 0.5px #efefef;
              background-color: #fafafa;
              opacity: 1 !important;

              .title {
                color: $primary900;
              }
            }

            .title {
              color: $white;
            }

            .heading {
              height: 100px;

              .trigger-icon img {
                margin-top: 0;
              }
            }
          }

          > .description {
            padding: 0 3px 0 8px;

            &.is-open {
              margin-top: 0;
            }

            &.is-close {
              margin-top: 0;
            }

            .list {
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }

  .collapse-css-transition {
    transition: height 0.2s ease;
  }
}
.ready {
  padding:2% 0%;
  border-top: 1px solid #80808050;
  text-align: center;

}

.ready_title {
  margin: 0 0 32px;
  color: #39B6ED;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px;
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    font-size: 50px;
    line-height: 70px;
  }
}