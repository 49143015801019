@import '../../../../scss/site-variables';

.header {
  --navbar-width: 792px;
  --link-width: 180px;
  --link-space: 24px;
  position: sticky;
  @media (max-width: 1439px) {
    --navbar-width: 508px;
    --link-width: 100px;
    --link-space: 36px;
  }
}

.navbar {
  background-color: $white;
  text-align: center;
}

.navbar_brand {
  position: absolute;
}

.navbar_header {
  position: relative;
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1439px) {
    max-width: 960px;
  }
  @media (max-width: 1023px) {
    max-width: 712px;
  }
  @media (max-width: 767px) {
    max-width: unset;
    padding: 0 12px;
  }
}

.navbar_nav {
  display: flex;
  width: var(--navbar-width);
  justify-content: space-between;
  padding: 18px 0 11px;
  margin: 0 auto;
  @media (max-width: 1439px) {
    width: var(--navbar-width);
    padding: 23px 0 22px;
  }
  @media (max-width: 767px) {
    width: auto;
  }
}

.navbar_link {
  display: block;
  width: var(--link-width);
  color: $nav-tab-color;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 200ms ease-in, color 200ms ease-in;

  &:hover {
    background-color: $grey300;
    color: $body-color;
    text-decoration: none;
  }
  &:focus {
    color: $nav-tab-color;
    outline: none;
    text-decoration: none;
  }
  &:hover:focus {
    color: $body-color;
  }
  @media (max-width: 1439px) {
    width: 100px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.21px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.navbar_link___active {
  color: $body-color;
  &:focus {
    color: $body-color;
  }
  @media (max-width: 1439px) {
    font-weight: 500;
  }
  @media (max-width: 767px) {
    display: block;
  }
}

.navbar_link___disabled {
  color: $grey400;
  cursor: not-allowed;
  &:active {
    pointer-events: none;
  }
  &:hover,
  &:focus,
  &:hover:focus {
    background-color: unset;
    color: $grey400;
  }
}

.progressBar {
  --step: 0;
  position: relative;
  width: 100%;
  height: 10px;
  background-color: $progress-bar-color;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) inset;
}

.progressBar___step_packages {
  --step: 1;
}
.progressBar___step_delivery {
  --step: 2;
}
.progressBar___step_checkout {
  --step: 3;
}

.progressStrip {
  position: absolute;
  z-index: 999;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: inherit;
  background-color: $primary500;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transform: translate(
    calc(
      (100vw - var(--navbar-width)) / 2 + (var(--link-width) + var(--link-space)) * var(--step) +
        (var(--link-width) / 2)
    )
  );
  transition: transform 300ms ease;
  &::after {
    position: absolute;
    top: 50%;
    right: -12px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: $primary500;
    border-radius: 100%;
    box-shadow: 1px 3px 4px -1px rgba(0, 0, 0, 0.2);
    content: '';
    transform: translateY(-50%);
  }
  @media (max-width: 767px) {
    width: 50%;
    transform: translate(100vw);
  }
}
