@import '../../../scss/site-variables';

.orders {
  flex-grow: 1;
  @media (max-width: 1023px) {
    padding: 0 16px;
  }
}

.table {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.table__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  background-color: $white;
  box-shadow: 0 0 2px 0 #00000019;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.table__cell {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  padding: 16px 0;
  color: $primary900;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  @media (max-width: 767px) {
    justify-content: flex-start;
    padding: 16px 12px 16px 20px;
    color: $grey800;
  }
}

.table__cell___shrink {
  flex: 0 1 72px;
  @media (max-width: 767px) {
    flex-basis: 24px;
  }
}

.table__cell___grow {
  @media (max-width: 767px) {
    flex: 1 0 100%;
    justify-content: space-between;
    order: 3;
    padding: 4px 12px 4px 20px;
    background: $grey300;
    color: $primary900;
  }
}

.table__cell___secondary {
  @media (max-width: 767px) {
    display: none;
  }
}

.table__cellTotal {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.table__action {
  height: 24px;
  padding: 0;
  border: 0;
  margin: 0;
  appearance: none;
  background: none;
  color: $grey600;
  font-size: 24px;
  line-height: 1;
  outline: 0;
}

.table__header {
  padding: 20px 0;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    display: none;
  }
}

.table__headerCell {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  color: $grey600;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.table__headerCell___shrink {
  flex: 0 1 72px;
}

.order {
  flex-grow: 1;
  @media (max-width: 1023px) {
    padding: 0 16px;
  }
}

.order__back {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 20px;
  background-color: $white;
  box-shadow: 0 0 2px 0 #00000019;
  color: $primary900;
  text-transform: uppercase;
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

.order__icon {
  margin-right: 12px;
  font-size: 24px;
  transform: rotate(180deg);
}

.list {
  background-color: $white;
  box-shadow: 0 2px 4px 0 #0000001a;
}

.list__date {
  padding: 4px 20px;
  background-color: $primary500;
}

.list__items {
  padding: 4px 0 16px;
  border-bottom: 1px solid $grey300;
  margin: 0 20px;
}

.list__item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.list__itemName {
  flex-grow: 1;
  margin-left: 16px;
}

.list__total{
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 24px;
}
.list__discounts{
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: $accent500;
}
.discount__text{
  color:$white;
}

.list_balances{
    display: flex;
  justify-content: space-between;
  padding: 16px 20px 24px;
    border-top: 1px solid #EEE;
  margin: 0 20px;
  @media screen and (max-width: 767px) {
   flex-direction: column;
  
    
  }


}

.list_internal{
  @media screen and (max-width: 767px) {
display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    
  
    
  }
}

.balance_title{
  @media screen and (max-width: 767px) {
  
    font-size: small;
  }
    
  
}

.negative_balance {
  background-color: #F1040d;
  color: #FFF !important;
}

.negative_balance * {
  color: #FFF !important;
}