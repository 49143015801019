@import '../../scss/site-variables';

.label {
  display: block;
  margin-bottom: 1%;

  color: $primary900;

  font-size: 14px;

  font-weight: 400;
}

.selectDay {
  display: flex;
  height: $input-height;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border: $border-width-1 solid $input-border-color;
  background-color: $white;
  border-radius: 5px;
  color: $input-color;
  font-size: 16px;

  &:focus {
    border-color: $border-blue;
    color: $grey900;
  }
}

.day {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: $primary900;
  cursor: pointer;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
}

.day_selected {
  background-color: $primary500;
  color: $white;
}

.day_disabled {
  opacity: .65;
  pointer-events: none;
}

@media (max-width: $screen-xs) {
  .label {
    font-size: 14px;
  }

  .selectDay {
    font-weight: 500;
  }
}


.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
    z-index: 99999;
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  width: 100%; /* Full width */

  height: 100%; /* Full height */

  padding-top: 20%; /* Location of the box */


  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}


.modalContent {
  width: 50%;

  padding: 20px;

  margin: auto;

  background-color: #fefefe;
  border-radius: 4px;
  @media screen and (max-width: $screen-sm) {
    z-index: 99999;
    width: 90%;
    margin-top: 50%;
    font-size: small;
  }
    
  
}


.buttonRow{
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 2% 0%;
}

.yesButton{
  padding: 2% 4%;

  background-color: $accent700;
  border-radius: 4px;

  color: $white;
  cursor: pointer;
}

.noButton{
  padding: 2% 4%;

  background-color: $site-red;
  border-radius: 4px;

  color: $white;
  cursor: pointer;

}