@import '../../scss/site-variables';

.input {
  position: relative;
}
.input__field {
  display: block;
  width: 100%;
  height: 47px;
  padding: 10px 10px 10px 5px;
  border: 0;
  border-bottom: 2px solid $primary900;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  color: $primary900;
  font-size: 16px;
}
.input__field:focus {
  outline: none;
}
.input__field_error {
  border-bottom-color: $site-red;
  color: $site-red;
}

.input__label {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  color: $primary900;
  font-size: 24px;
  font-weight: 500;
  pointer-events: none;
  text-align: center;
  transition: 0.2s ease all;
}

.active .input__label {
  transform: translateY(-15px) scale(0.6);
}

.bar {
  position: relative;
  display: block;
  width: 300px;
}
.bar::before,
.bar::after {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: $primary500;
  content: '';
  transition: 0.2s ease all;
}
.bar::before {
  left: 50%;
}
.bar::after {
  right: 50%;
}
.error .bar::before,
.error .bar::after {
  background-color: $site-red;
}

.input__field:focus ~ .bar::before,
.input__field:focus ~ .bar::after {
  width: 50%;
}

.input__errorMessage {
  color: $site-red;
}

.input__dropdown {
  position: absolute;
  z-index: 5;
  top: calc(100%);
  right: 0;
  left: 0;
  max-height: 25em;
  padding: 0;
  border: 1px solid $primary500;
  border-top-width: 0;
  margin-top: -1px;
  background: #fff;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.2s, border 0.2s;
}

.input__dropdown_hidden {
  overflow: hidden;
  max-height: 0;
  border-width: 0;
}

.input__dropdown_item {
  padding: 0.5em 0.65em;
  cursor: pointer;
  font-size: 14px;
}

.input__dropdown_item:hover,
.input__dropdown_item:focus {
  background: #f5f5f5;
}

.input__dropdown_item_active {
  background: $primary500;
  color: $white;
}

.input__dropdown_item_active:hover,
.input__dropdown_item_active:focus {
  background: #ccc;
}
