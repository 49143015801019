@import '../../scss/site-variables';

.switch {
  position: relative;
  color: $primary900;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.switch_error {
  color: $site-red;
}

.switch__field {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.switch__label {
  display: block;
  padding: 0 0 0 60px;
  margin: 0;
  font-weight: inherit;
  letter-spacing: 0.51px;
  user-select: none;
}

.switch__label::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 48px;
  height: 24px;
  border: 1px solid $grey600;
  background-color: $primary400;
  background-image: linear-gradient(to bottom, #fff9, #fff9),
    linear-gradient(to bottom, #fdfdfd, #e7e9f3 88%, #d8dbe7);
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 #00000019;
  content: '';
  cursor: pointer;
}

.switch__field:checked + .switch__label::before {
  border-color: $primary500;
  background-image: none;
}

.switch__label::after {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 16px;
  height: 16px;
  border: 1px solid $grey600;
  background: $white;
  border-radius: 50%;
  content: '';
  cursor: pointer;
  transition: 0.2s ease transform;
}

.switch__field:checked + .switch__label::after {
  border-color: $primary500;
  transform: translateX(22px);
}

.switch__field:focus + .switch__label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: none;
}

.switch__field_error + .switch__label::before {
  border-color: $site-red;
}

.switch__gauge::before {
  position: absolute;
  top: 0;
  left: 24px;
  color: $grey600;
  content: 'Off';
  cursor: pointer;
  font-size: 8px;
  font-weight: 500;

  letter-spacing: 0.14px;
  text-transform: uppercase;
}

.switch__field:checked ~ .switch__label > .switch__gauge::before {
  left: 8px;
  color: $white;
  content: 'On';
}

.switch__errorMessage {
  color: $site-red;
}
