@import '../../scss/site-variables';

.radio {
  --color: #{$primary500};
  position: relative;
  color: $primary900;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.radio_error {
  color: $site-red;
}

.radio_primary {
  --color: #{$primary500};
}

.radio_accent {
  --color: #{$accent500};
}

.radio__field {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  opacity: 0;
}

.radio__label {
  display: block;
  // padding: 0 0 20px 16px;
  margin: 0;
  font-weight: inherit;
  letter-spacing: 0.51px;
}

.radio__label::before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 50%;
  content: '';
}

.radio__label::after {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 10px;
  height: 10px;
  background-color: var(--color);
  border-radius: 50%;
  content: '';
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.radio__field:checked + .radio__label::before,
.radio__field:hover + .radio__label::before {
  border-color: var(--color);
}

.radio__field:checked + .radio__label::after {
  opacity: 1;
}

.radio__field:focus + .radio__label::before {
  box-shadow: 0 0 0 3px var(--color);
  outline: 3px solid transparent;
}

.radio__label_hasLabel {
  padding: 0 0 0 28px;
}

.radio__field_error {
  border-color: $site-red;
}

.radio__field:focus {
  border-color: var(--color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}
