@import '../../scss/site-variables';



.hero_content {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-sm) {
        flex-direction: column;
        padding-top: 15%;
      
    }
  
 
}

.hero_block {

  @media (min-width: 1024px) {
    width: 50%;

  }
  @media (min-width: 1440px) {
    width: 50%;
  }
  display: flex;
  flex-direction: column;
  padding: 50px;

}

.centeration{
  @media (max-width: $screen-sm) {
   align-self: center;
  }
}
.hero{
  background-color: #E2F8FF;
}

.hero_title {
  margin: 0 0 32px;
  color:#39B6ED;
  font-size: 36px;
  line-height: 48px;
     @media (max-width: $screen-sm) {
   text-align: center;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 70px;
  }
  @media (min-width: 1440px) {
    margin-bottom: 40px;
  }
}

.hero_subtitle {
  margin: 0 0 32px;
  font-size: 16px;
  font-weight: bold;
  @media (max-width: $screen-sm) {
   text-align: center;
  }
  line-height: 24px;
  @media (min-width: 1024px) {
    font-size: xx-large;
    line-height: 28px;
  }
}

.hero_info {
  margin: 0 0 32px;
  color: #003459;
  font-size: 14px;
  @media (max-width: $screen-sm) {
   text-align: center;
  }
  line-height: 24px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.hero_button {
  display: flex;
  
  justify-content: center;
  @media (min-width: $screen-sm) {
    display: inline-flex;
  }
}

.hero_image {
  display: block;
  width: 120px;
  margin: 0 auto 32px;
        @media (max-width: $screen-sm) {
          width: 70%;
    // display: none;


  }
  @media (min-width: $screen-sm) {
    width: 224px;
    order: 1;
    margin: 0 60px 0 0;
  }
  @media (min-width: 1024px) {
    width: 400px;
    margin-right: 0;
  }
  @media (min-width: 1440px) {
    width: 400px;
  }
}
