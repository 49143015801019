@import '../../../scss/site-variables';

.sidebar {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: $white;
  box-shadow: 0 0 2px 0 $shadow-color;
}

.sidebar___isOpen {
  @media (max-width: 1023px) {
    &::before {
      position: fixed;
      z-index: 1;
      top: 156px;
      right: 0;
      bottom: 0;
      left: 0;
      backdrop-filter: blur(5px);
      background-color: #212121c0;
      content: '';
    }
  }
}

.sidebar__button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border: 0;
  appearance: none;
  background-color: transparent;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.5;
  text-transform: uppercase;

  > svg {
    margin: 0 8px;
    font-size: 24px;

    &:first-child {
      color: $primary500;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.sidebar__button___isOpen {
  > svg:last-child {
    transform: rotate(180deg);
  }
}

.sidebar__wrapper {
  padding: 28px 16px 16px;

  @media (max-width: 1023px) {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    padding: 12px 28px 20px;
    background-color: $white;
  }
}

.sidebar__wrapper___hidden {
  @media (max-width: 1023px) {
    display: none;
  }
}

.sidebar__links {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 1px solid $grey300;
  margin-bottom: 12px;
}

.sidebar__link {
  display: inline-flex;
  margin-bottom: 12px;
  color: $grey800;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.51px;
  line-height: 2;
  text-decoration: none;
  text-transform: uppercase;

  > svg {
    margin-right: 12px;
    color: $grey600;
    font-size: 24px;
  }

  &:global(.active),
  &:hover {
    color: $primary900;
    font-weight: 500;
    text-decoration: none;

    > svg {
      color: $primary500;
    }
  }
}

.sidebar__info {
  color: $primary500;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
}
