.header {
  margin: 0 0 20px;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  button + button {
    margin-left: 14px;
  }
}
