@import '../../scss/site-variables';

.menu {
  .menu__list {
    padding: 8px 0;
  }

  .menu__button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 0;
    background-color: $white;
    color: inherit;
    line-height: 0;

    &:hover {
      background-color: $grey300;
    }

    &:focus {
      outline: none;
    }
  }

  .menu__icon {
    font-size: 24px;
    line-height: 1;
  }

  .menu__icon___big {
    font-size: 32px;
  }

  .menu__item {
    display: block;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      background-color: $grey300;
      color: $primary900;
    }
  }

  .menu__link {
    display: block;
    padding: 11px 24px;
    color: $primary900;
    font-size: 16px;
    line-height: 1.5;
    outline: 0;

    &:hover,
    &:focus,
    &:active {
      color: $primary900;
      text-decoration: none;
    }
  }

  .menu__itemButton {
    width: 100%;
    padding: 11px 24px;
    border: 0;
    appearance: none;
    background-color: transparent;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  .menu__divider {
    margin: 8px 0;
    background-color: $grey300;
  }

  .menu__header {
    padding: 11px 24px;
    color: $primary900;
    font-size: inherit;
    text-transform: none;
  }
}
