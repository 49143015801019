.navbar__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  background: #daf2fc;

  .img__container {
    margin-top: 5rem;
    img {
      height: 210px;
    }
  }

  .navbar__intro {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
    margin-top: 4rem;

    h1,h3 {
      text-align: center;
    }

    .navbar__intro__text {
      p {
        padding-right: 2rem;
        padding-left: 2rem;
        color: #8f9698;
        text-align: center;
      }
    }

    h1 {
      color: #013459;
      font-size: 42px;
      font-weight: 600;
    }
    h3 {
      color: #37b7ed;
      font-size: 28px;
    }
    .navbar__intro__removed {
      display: flex;
      margin-top: 3rem;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        br {
          display: none;
        }
      }

      .intro__removed__text {
        padding: 0 3rem;
        p {
          color: #8f9698;
          font-size: 20px;
          line-height: 28px;
          text-decoration: line-through;
          
        }
      }
    }
  }
  
}