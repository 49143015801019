@import '../../scss/site-variables';

.modal {
  --border-bottom: 62%;
  min-width: 656px;
  padding: 170px 48px 32px;
  background-color: $white;
  background-image: url('../../assets/pickup-truck.svg'),
    linear-gradient(
      0deg,
      $white 0%,
      $white var(--border-bottom),
      $primary500 var(--border-bottom),
      $primary500 100%
    );
  background-position: center 33px, center;
  background-repeat: no-repeat;
  background-size: 98px, 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 $shadow-color;
  @media (max-width: 1023px) {
    min-width: 528px;
  }
  @media (max-width: 767px) {
    --border-bottom: 69%;
    width: calc(100vw - 32px);
    min-width: auto;
    padding: 170px 20px 50px;
  }
}

.modal_close {
  position: absolute;
  top: 0;
  right: 0;
  color: $white;
  cursor: pointer;
  font-size: 48px;
  transition: transform 0.1s linear;
  &:hover {
    transform: scale(1.2);
  }
  @media (max-width: 767px) {
    top: 28px;
    right: 10px;
  }
}

.modal_header {
  margin: 0 0 8px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.modal_actions {
  display: flex;
  justify-content: stretch;
  margin: 84px 36px 0;
  @media (max-width: 1023px) {
    margin: 60px 0 0;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 46px 0 0;
  }

  button + button {
    margin-left: 10px;
    @media (max-width: 767px) {
      margin-top: 12px;
      margin-left: unset;
    }
  }
}
