@import '../../scss/site-variables';

.textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  color: $primary900;
  font-size: 16px;
}

.textarea_error {
  color: $site-red;
}

.textarea__field {
  padding: 15px 32px 15px 17px;
  border: 1px solid $grey400;
  appearance: none;
  background-color: $white;
  border-radius: 6px;
  box-shadow: none;
  font-weight: 400;
  line-height: 1.5;
  resize: vertical;
}

.textarea__label {
  position: absolute;
  top: 2px;
  left: 20px;
  max-width: 100%;
  padding: 0 5px;
  background-color: $white;
  font-size: 14px;
  font-weight: 400;
}

.textarea__field_error {
  border-color: $site-red;
}

.textarea__field_success {
  border-color: $accent500;
}

.textarea__field:focus {
  border-color: $primary500;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.textarea__errorMessage {
  color: $site-red;
  font-size: 12px;
  line-height: 15px;
}

.textarea__oval {
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto 0;
  background-color: $primary500;
  border-radius: 100%;
}

.textarea__loading {
  position: absolute;
  right: 15px;
  bottom: 30px;
  display: block;
  width: 25px;
  height: 25px;
  animation: loadingAnimation 720ms steps(8, start) infinite reverse;
  background-image: url('../../assets/loading.svg');
  background-size: cover;
  border-radius: 100%;
}

@keyframes loadingAnimation {
  to {
    transform: rotate(360deg);
  }
}

.textarea__oval_error {
  background-color: $site-red;
}
