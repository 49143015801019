.home_pannel {
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin-top: 125px;
  background-color: rgba(250, 250, 250, 100%);

  ul.home_icon_ul {
    display: flex;
    width: 100%;
    max-width: 800px;
    align-items: center;
    padding: 0;
    justify-items: center;
    list-style-type: none;
    text-align: center;
    word-spacing: -5px;

    li {
      display: inline-block;
      width: 33.333%;
      list-style-type: none;
      text-align: center;
      vertical-align: top;
      word-spacing: 0;

      img {
        display: block;
        margin: 0 auto 20px;
      }

      a {
        display: block;
        color: $grey900;
        font-size: $size-18;
        font-weight: 600;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $primary500;
        }
      }
    }
  }
}

.home-content {
  padding-top: 240px;
  background-color: $white;
  color: $primary900;

  h1 {
    margin-bottom: 40px;
    letter-spacing: -0.5px;
    line-height: 48px;
  }

  .form-control[disabled] {
    background-color: inherit;
  }

  .container {
    position: relative;
    max-width: 1200px;

    .share-icons {
      position: absolute;
      top: 150px;
      left: -54px;

      a {
        img {
          display: block;
          margin-bottom: 10px;
        }
      }
    }

    .main {
      display: flex;

      .info {
        order: 0;

        p {
          margin-bottom: 90px;
        }
      }

      .box {
        height: 200px;
        order: 1;
        background-color: #ededed;
        border-radius: 24px;
      }

      .animation-container {
        img {
          width: 100%;
          max-width: 420px;
        }
        @media (max-width: $screen-xs) {
          display: flex;
          justify-content: center;

          img {
            max-width: 320px;
          }
        }
      }
      @media (max-width: $screen-xs) {
        flex-direction: column;

        .info {
          order: 1;

          .form-signIn {
            display: block;
            width: 100%;
            margin-top: 45px;
            text-align: center;
          }
        }

        .box {
          order: 0;
        }
      }
    }

    .actions-heading {
      margin-top: 60px;
      margin-bottom: 40px;
      text-align: center;
    }

    .more {
      margin: 180px 0 140px;

      @media (max-width: $screen-xs) {
        margin: 140px 0;
      }
    }

    .services-heading {
      margin-bottom: 30px;
    }

    .services-content {
      display: grid;
      padding: 32px 100px 0;
      grid-row-gap: 38px;
      grid-template-columns: repeat(8, 1fr);

      .item {
        grid-column: span 2;
        transition: transform 0.2s ease-in-out;
        will-change: transform;

        &:first-child {
          grid-column-end: 4;
        }

        h5 {
          margin-top: 22px;
          color: $primary900;
          font-size: 20px;

          @media (max-width: $screen-xs) {
            font-size: 20px;
          }
        }
        &:hover,
        &:active {
          text-decoration: none;
          transform: scale(1.1);
        }
        &:focus {
          text-decoration: none;
        }

        img {
          width: 96px;
          height: 96px;
        }
      }

      @media (max-width: 1023px) {
        padding: 32px 20px 0;
      }

      @media (max-width: $screen-sm) {
        padding: 32px 0 0;
      }

      @media (max-width: $screen-xs) {
        grid-row-gap: 25px;
        grid-template-columns: repeat(4, 1fr);

        .item {
          &:first-child {
            grid-column-end: auto;
          }
          &:last-child {
            grid-column-end: 4;
          }
        }
      }
    }

    .info-1 {
      color: $primary900;
      font-weight: 500;
      line-height: 1.4;
    }
  }

  .actions_outer {
    margin-bottom: 50px;
    background-image: url('../../assets/actions_bg.svg');
    background-position: 0 20px;
    background-repeat: no-repeat;
    background-size: cover;

    .actions {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: minmax(177px, 1fr) minmax(177px, 1fr) minmax(177px, 1fr);

      .action-box {
        height: auto;
        min-height: 150px;
        padding: 30px;
        background-color: $white;
        border-radius: 3px;
        box-shadow: 2.5px 5px 15px 0 rgba(57, 182, 237, 0.12);
        transition: all 0.2s ease-in-out;

        img {
          display: block;
        }

        &.active,
        &:hover {
          background-color: $primary500;
          box-shadow: 2.5px 5px 15px 0 rgba(57, 182, 237, 0.12);
          color: $white;
          transform: scale(1.05, 1.1);
          @media (max-width: $screen-xs) {
            margin-top: 13px;
            transform: scale(1.02, 1.1);
          }
          a {
            color: $white;
          }
        }

      }
      @media (max-width: $screen-xs) {
        grid-template-columns: 1fr;
      }
    }
  }

  .location {
    .form-signIn {
      display: block;

      .form-group {
        + button img {
          margin-top: -4px;
        }
        @media (max-width: $screen-md) {
          margin-right: 20px;
        }
        @media (max-width: $screen-xs) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    // padding-bottom: 295px;

    h1 {
      margin-bottom: 30px;
    }
  }
  @media (min-width: $screen-md) {
    // padding-bottom: 145px;
  }
  @media (min-width: $screen-sm) {
    // padding-bottom: 20%;
  }

  @media (max-width: $screen-md-max) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }

  @media (max-width: $screen-xs) {
    padding-bottom: 100px;

    h1 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }
}

.form-signIn {
  display: inline-block;
  width: 100%;
  max-width: 565px;
  margin: 76px auto 0;

  .form-group,
  .button {
    width: calc(50% - 20px);
    float: left;
  }

  .form-group {
    margin-right: 30px;

    .form-control {
      width: 100%;
      height: 66px;
      background-color: inherit;
      color: $primary900;
      line-height: 66px;
    }
  }

  @media (max-width: $screen-xs) {
    display: block;
    width: 100%;
  }

  @media (max-width: $screen-xs) {
    max-width: 268px;
    margin-top: 56px;

    .form-group {
      margin-bottom: 45px;
    }

    .button {
      margin: 0;
    }

    .form-group,
    .button {
      width: 100% !important;
    }
  }
}

////////new design start///////
.new-home-content{
  //padding-top: 50px;
  height: 970px;
  padding: 290px 0 0;
  margin-top: -2px;
  background: url('../../assets/washmix-process/Background-min.png') no-repeat;
  background-size: contain;
  .container{
    margin: auto;
    .title{
      padding: 0 140px;
      color: #FFF;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.25;
    }
    .info-1-new{
      color: #FFF;
      font-size: 30px;
      font-weight: 400;
    }
    p{
      padding-top: 25px;
      color: #FFF;
      font-size: 18px;
      line-height: 1.4;
    }
  }
}
.afterPrev{
  padding-top: 50px;
  padding-bottom: 120px;
  background-color: #f9fbfe;
  .container{
    .bg-image{
      width: 100%;
      height: 640px;
      background: url('../../assets/washmix-process/Image-two-min.png') no-repeat center;
      background-size: contain;
    }
  }
}

.road{
  width: 100%;
  height: 210px;
}
.road-section-pc-r{
  margin-top: -100px;
  background: url('../../assets/newImages/road-pc-r.svg') no-repeat center;
  background-size: contain;
}
.road-section-mes-l{
  margin-top: 50px;
  margin-bottom: 50px;
  background: url('../../assets/newImages/road-mes-l.svg') no-repeat center;
  background-size: contain;
}
.road-section-cl-r{
  margin-top: 20px;
  background: url('../../assets/newImages/clear-road-r.png') no-repeat center;
  background-size: contain;
}
.road-section-cl-l{
  margin-top: 20px;
  background: url('../../assets/newImages/clear-road-l.svg') no-repeat center;
  background-size: contain;
}
.road-section-car-l{
  margin-top: 50px;
  margin-bottom: 50px;
  background: url('../../assets/newImages/road-car-l.svg') no-repeat center;
  background-size: contain;
}
.road-section-bus-l{
  margin-top: 50px;
  margin-bottom: 10px;
  background: url('../../assets/newImages/road-bus-l.svg') no-repeat center;
  background-size: contain;
}
.road-section-bascet-r{
  margin-top: -70px;
  background: url('../../assets/newImages/road-basced-r.svg') no-repeat center;
  background-size: contain;
}
.road-section-car-l-bl{
  margin-top: 50px;
  margin-bottom: 50px;
  background: url('../../assets/newImages/road-car-bl.svg') no-repeat center;
  background-size: contain;
}
.road-section-man-r{
  margin-top: 50px;
  margin-bottom: 50px;
  background: url('../../assets/newImages/road-man.svg') no-repeat center;
  background-size: contain;
}
.text{
  margin-top: 20%;
  h2, p{
    color: #FFF;
  }
  h2{
    font-size: 25px;
    font-weight: 700;
  }
  p{
    font-size: 20px;
    line-height: 1.2;
  }
}

.process-I-section{
  padding: 70px 0;
  background-color: #003459;

  .head-title{
    padding-bottom: 70px;
    text-align: center;
    .title{
      color: #FFF;
      font-size: 38px;
    }
  }
  .image{
    width: 100%;
    height: 500px;
    background-size: 500px;
  }
  .img-1{
    background: url('../../assets/newImages/cupboard.svg') no-repeat;
  }
  .img-2{
    margin-top: 35px;
    background: url('../../assets/newImages/home.svg') no-repeat;
  }
  .img-3{
    background: url('../../assets/newImages/bag.svg') no-repeat;
  }

  .text{
    h2{
      font-size: 25px;
    }
    p{
      padding-right: 160px;
      font-size: 18px;
      font-weight: 300;
      strong{
        font-weight: 600;
      }
    }
  }
  .text-l{
    padding-right: 220px;
    h2{
      padding-right: 85px;
      padding-bottom: 15px;
    }
    p{
      padding-right: 0;
    }
  }
}

.process-II-section{
  padding-bottom: 150px;
  background-color: #f0f5f8;
  .image{
    width: 100%;
  }
  .img-1{
    height: 850px;
    background: url('../../assets/washmix-process/weekly_recuring-min.png') no-repeat center;
    background-size: contain;
  }
  .img-2{
    height: 540px;
    background: url('../../assets/newImages/phone-min.png') no-repeat center;
    background-size: contain;
  }


  .text{
    padding-right: 175px;
    margin-top: 35px;
    h2{
      color: #6e6e6e;
      font-size: 25px;
      font-weight: 700;
    }
    p{
      color: #6e6e6e;
      font-size: 16px;
      line-height: 1.2;
    }
    .bottom-sec-p{
      padding-right: 20px;
    }
    h3{
      color: #39b6ed;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .discription{
    h1{
      color: #39b6ed;
      font-size: 21px;
      font-weight: 700;
    }
    .p3{
      padding-right: 80px;
    }
    p{
      color: #6e6e6e;
      font-size: 16px;
    }
  }
}

.process-III-section{
  padding: 70px 0 100px;
  background-color: #003459;
  .top-road{
    margin-top: -200px;
  }
  .col-lg-12{
    .title-sec{
      text-align: center;
      h1{
        color: #FFF;
        font-size: 38px;
        font-weight: 500;
      }

    }
  }
  .text{
    padding-right: 200px;
    margin-top: 50px;
    h2{
      font-size: 25px;
      font-weight: 600;
    }
    p{
      font-size: 16px;
      strong{
        font-weight: 600;
      }
    }
  }
  .process-text{
    padding-right: 80px;
  }
  .img-1{
    height: 300px;
    background: url('../../assets/newImages/driver.svg') no-repeat center;
    background-size: contain;
  }
  .img-2{
    min-width: 340px;
    max-width: 960px;
    height: 495px;
    min-height: 200px;
    max-height: 640px;
    background: url('../../assets/newImages/fabric-min.png') no-repeat;
    background-size: contain;
  }
}

.list{
  strong{
    margin-left: 40px;
    line-height: 1.1;
  }
}
.process-section{
  padding-bottom: 150px;
  background: url('../../assets/newImages/bg-process.svg') no-repeat center;
  background-size: cover;
  .title{
    margin-top: 20px;
    margin-bottom: 100px;
    text-align: center;
    h1{
      color: #003459;
      font-size: 38px;
      font-weight: 600;
    }
  }
  .img{
    height: 450px;
  }
  .text-section{
    padding-left: 30px;
    h1{
      padding-right: 145px;
      color: #003459;
      font-size: 30px;
      font-weight: 600;
    }
    span, p{
      padding-right: 145px;
      color: #6e6e6e;
      font-size: 16px;
      line-height: 1.3;
    }

  }
  .part-1{
    p{
      padding-right: 210px;
    }
  }
  .part-2{
    p{
      padding-right: 140px;
    }
  }
  .part-3{
    margin-top: 100px;
    .text-section{
      p{
        padding-right: 143px;
      }
    }

  }
  .part-4{
    .text-section{
      p, span{
        padding-right: 130px;
      }
      h1{
        span{
          padding-right: 0;
          color:#003459;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
  .part5{
    .text-section{
      margin-top: 60px;
      p{
        padding-right: 95px;
      }
    }
  }
  .part6{
    margin-top: -220px;
    .text-section{
      margin-top: 100px;
      p{
        padding-right: 165px;
      }
    }
  }
  .part7{
    margin-top: -55px;
  }
  .part-8{
    p{
      padding-right: 100px;
    }
  }
  .img-1{
    background: url('../../assets/newImages/basic.png') no-repeat center;
    background-size: contain;
  }
  .img-2{
    margin-top: -50px;
    background: url('../../assets/newImages/laundry.svg') no-repeat center;
    background-size: contain;
  }
  .img-3{
    margin-top: 30px;
    background: url('../../assets/newImages/clean.svg') no-repeat center;
    background-size: contain;
  }
  .img-4{
    margin-top: -60px;
    background: url('../../assets/newImages/spotting.svg') no-repeat center;
    background-size: contain;
  }
  .img-5{
    margin-top: 0;
    background: url('../../assets/newImages/unit.svg') no-repeat center;
    background-size: contain;
  }
  .img-6{
    background: url('../../assets/newImages/press.svg') no-repeat center;
    background-size: contain;
  }
  .img-7{
    margin-top: -90px;
    background: url('../../assets/newImages/long.svg') no-repeat center;
    background-size: contain;
  }
  .img-8{
    background: url('../../assets/newImages/sec-press.svg') no-repeat center;
    background-size: contain;
  }

  .img-r-arrow{
    height: 220px;
    margin-right: 130px;
    background: url('../../assets/newImages/r-arrow.svg') no-repeat center;
  }
  .img-l-arrow{
    height: 220px;
    margin-top: -120px;
    margin-left: -80px;
    background: url('../../assets/newImages/l-arrow.svg') no-repeat left;
  }
}

.process-footer-section{
  padding-bottom: 50px;
  background-color: #003459;
  .road-section-car-l-bl{
    margin-top: -105px;
  }
  .text-section{
    margin-top: 120px;
    margin-left: 229px;
    span{
      color: #39b6ed;
    }
    p{
      padding-right: 0;
      font-size: 16px;
      font-weight: 300;
      strong{
        font-weight: 600;
      }
    }
  }
  .text-section-2{
    margin-top: 80px;
    margin-left: 0;
  }
  .img-1{
    height: 500px;
    background: url('../../assets/newImages/plastic-bag-min.png') no-repeat center;
    background-size: contain;
  }
  .img-2{
    height: 550px;
    background: url('../../assets/newImages/delivery-man-min.png') no-repeat center;
    background-size: contain;
  }
}

.bg-img{
  width: 100%;
  height: 1100px;
  margin-top: -2px;
  background: url('../../assets/newImages/footer-img-min.png') no-repeat;
  background-size: cover;
}

.FAQ-section{

  .faq-title{
    color: #003459;
    font-size: 38px;
    font-weight: 700;
  }
}
////////new design end///////

@media (max-width: $screen-xs) {
  .home_pannel {
    ul.home_icon_ul {
      flex-direction: column;
      margin: 0 auto;

      li {
        width: 100%;
        margin: 30px 0;
      }
    }
  }
}

@media only screen and (max-width: 991px){
  .text{
    margin-top: 55px;
  }
  .afterPrev{
    padding-top: 30px;
    padding-bottom:30px;
    .container{
      .bg-image{
        background: url('../../assets/newImages/marshrut-sm-min.png') no-repeat center;
        background-size: contain;
      }
    }
  }
  .process-section{
    .container{
      .row{
        .col-lg-12{
          .img-r-arrow, .img-l-arrow{
            display: none;
            background: none;
          }
        }
      }
    }

    .part6, .part7{
      margin-top: 0;
    }
  }

  .process-I-section {
    .img-3{
      background-position: center;
    }
    .head-title {
      padding-bottom: 0;
    }
  }
  .process-III-section{
    .col-lg-12 {
      .title-sec {
        h1{
          margin-bottom: 30px;
        }
      }
    }
  }
  .process-footer-section {
    .text-section{
      margin-top: 0;
      margin-left: 30px;
    }
  }
}
@media only screen and (max-width: 1100px){
  .process-section{
    .container{
      .row{

        .part-3, .img-3, .img-4, .img-6, .img-7{
          margin-top: 0;
        }
        .col-lg-6{
          margin-top: 50px;
          margin-bottom: 30px;
        }

      }
    }
  }
  .bage-section{
    .badge-container-new{
      .badge-main{
        padding: 15px;
        p{
          padding-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  //.title, .title-sec{
  //  h1{
  //    font-size: 35px;
  //  }
  //}
  .new-home-content{
    height: 330px;
    padding: 90px 0 0;
    background-size: cover;
    .main{
      .info-2{
        h1{
          padding: 0;
          margin-bottom: 12px;
          font-size: 20px;
        }
        .info-1-new{
          font-size: 14px;
        }
        p{
          padding-top: 12px;
          margin-bottom: 0;
          font-size:10px;
        }
      }
    }
  }

  .after-prev{
    .afterPrev{
      padding-top: 30px;
      padding-bottom:30px;
      .container{
        .bg-image{
          height: 300px;
        }
      }
    }
  }

  .process-section{
    .title{
      margin-bottom: 0;
      h1{
        font-size: 20px;
      }
    }
    .part-3, .part-4, .part-5, .part-6, .part-7, .part-8{
      .text-section{
        p{
          padding-right: 0;
        }
      }
    }
    .part-4{
      .text-section{
        h1{
          span{
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
    .text-section{
      padding-left: 0;
      span{
        strong{
          font-size: 14px;
        }
      }
      h1{
        padding-right: 0;
        font-size: 16px;
        span{
          font-size: 16px;
          font-weight: 700;
        }
      }
      p{
        padding-right: 0;
        font-size: 14px;
      }

    }
    .img {
      height: 350px;
    }
    .container {
      .row {
        .col-lg-6 {
          margin-top: 0;
        }
      }
    }
  }

  .process-I-section{
    padding-top: 15px;
    .head-title{
      padding-bottom: 0;
      .title{
        font-size: 20px;
      }
    }
    .row{
      .text{
        margin-top: 20px;
        h2{
          padding-right: 0;
          font-size: 15px;
        }
        p{
          padding-right: 0;
          font-size: 14px;
        }
      }
      .text-l{
        padding-right: 0;
      }
      .image{
        height: 300px;
      }
      .road-section-cl-r{
        margin-top: 0;
      }
      .road-section-car-l {
        margin: 0;
      }
    }
  }
  .col-lg-12{
    .road{
      height: 150px;
    }
  }

  .process-II-section {
    .img-1{
      height: 235px;
    }
    .discription{
      h1{
        font-size: 16px;
      }
      p{
        font-size: 14px;
      }
      .p3{
        padding-right: 0;
      }
    }
    .text{
      padding: 0;
      margin: 0;
      h2{
        font-size: 18px;
        text-align: center;
      }
      h3{
        font-size: 18px;
      }
      p{
        font-size: 14px;
      }
    }
    .road-section-mes-l {
      margin-top: 0;
      margin-bottom: 0;
    }
}

.process-III-section {
.img-2{
  height: 270px;
  background-position: center;
}
.col-lg-12 {
  .title-sec {
    h1{
      font-size: 20px;
    }
  }
}
.text{
  padding-right: 0;
  margin-top: 30px;
  h2{
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
}
}

.process-footer-section{
  .text{
    margin-top: 0;
  }
  .text-section{
    margin: 0;
    margin-bottom: 30px;
    h2{
      font-size: 18px;
    }
    span, p{
      padding-right: 0;
      font-size: 14px;
    }
  }
  .img-1{
    height: 300px;
  }
}

.bg-img{
height: 260px;
background-size: contain;
}

.HowItWorks_checkService__1fLi_{
padding: 20px 0;
.GooglePlaces_button__1A92p{
  margin: auto;
}
}

.ContactsNew_contacts_header__2OVVR {
  margin-top: 30px;
  font-size: 20px;

}

  .FAQ-section{
    .faq-title{
      font-size: 20px;
    }
    div{
      font-size: 14px;
    }
  }

  .badge-section {
    .badge-container-new {
      .badge-main {
        .tagline{
          font-size: 18px;
        }
        .subtag {
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (min-width: 425px) and (max-width: 599px) {
.new-home-content{
height: 340px;
padding: 95px 0;
background-size: cover;
.main{
  .info-2{
    h1{
      padding: 0;
      margin-bottom: 12px;
      font-size: 22px;
    }
    .info-1-new{
      font-size: 15px;
    }
    p{
      padding-top: 12px;
      margin-bottom: 0;
      font-size:10px;
    }
  }
}
}
.after-prev{
.container{
  .bg-image{
    height: 320px;
  }
}
}

.process-I-section{
  padding-top: 40px;
.head-title{
  padding-bottom: 0;
  .title{
    font-size: 22px;
  }
}
.row{
  .text{
    margin-top: 18px;
    h2{
      padding-right: 0;
      font-size: 18px;
    }
    p{
      padding-right: 0;
      font-size: 15px;
    }
  }
  .text-l{
    padding-right: 0;
  }
  .image{
    height: 300px;
  }
  .road-section-cl-r{
    margin-top: 0;
  }
  .road-section-car-l {
    margin: 0;
  }
}
}
.col-lg-12{
.road{
  height: 150px;
}
}

.col-lg-12{
.road{
  height: 150px;
}
}

.process-II-section {
.discription  {
  h1{
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
  .p3{
    padding-right: 0;
  }
}
.img-1{
  height: 25vh;
}
.img-2{
  height: 350px;
}
.text{
  padding: 0;
  margin: 0;
  h2{
    font-size: 20px;
    text-align: center;
  }
  h3{
    font-size: 18px;
  }
  p{
    font-size: 15px;
  }
}
}


.process-III-section {

.col-lg-12 {
  .title-sec {
    h1 {
      font-size: 20px;
    }
  }
}
.text{
  padding-right: 0;
  h2{
    font-size: 18px;
  }
  p{
    font-size: 15px;
  }
}
.img-2{
  height: 270px;
  background-position: center;
}
}

.process-section {
  .img{
    height: 300px;
  }
  .part-1, .part-2, .part-3, .part-4, .part-5, .part-6, .part-7{
    .text-section{
      p{
        padding-right: 0;
      }
    }
  }
.title {
  margin-bottom: -50px;
  h1 {
    margin-bottom: 30px;
    font-size: 22px;
  }
}
.part-4{
  .text-section {
    h1{
      span{
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
.text-section{
  padding-right: 0;
  padding-left: 0;
  h1{
    padding-right: 0;
    font-size: 19px;
  }
  p{
    padding-right: 15px;
    font-size: 16px;
  }
  strong{
    font-size: 16px;
  }
}
}

.process-footer-section{
.text{
  h2{
    font-size: 20px;
  }
  p{
    padding-right: 0;
    font-size: 16px;
  }
}
}
.bg-img{
height: 360px;
background-size: contain;
}

.HowItWorks_checkService__1fLi_{
padding: 20px 0;
.GooglePlaces_button__1A92p{
  margin: auto;
}
}

.ContactsNew_contacts_header__2OVVR {
margin-top: 30px;
font-size: 25px
}

.FAQ-section{
.faq-title{
  font-size: 22px;
}
}

}
@media only screen and (min-width: 600px) and (max-width: 767px) {
.new-home-content{
height: 420px;
padding: 95px 0 0;
background-size: cover;
.main{
  .info-2{
    h1{
      padding: 0 75px;
      font-size: 25px;
    }
    .info-1-new{
      font-size: 16px;
    }
    p{
      font-size:14px;
    }
  }
}
}
.after-prev{
padding-top: 0;
.container{
  .bg-image{
    height: 360px;
  }
}
}
.process-I-section {
  .image {
    height: 450px;
    margin: auto;
  }
.head-title {
  .title {
    font-size: 25px;
  }
}
.text{
  h2{
    padding-right: 0;
    font-size: 20px;
  }
  p{
    padding-right: 0;
    font-size: 18px;
  }
}
.text-l{
  padding-right: 0;
  margin-top: 10px;
  .head-title {
    .title {
      font-size: 20px;
    }
  }
}
}
.process-II-section {
  .img-1 {
    height: 450px;
  }
.text{
  padding-right: 0;
  h2{
    font-size: 20px;
  }
  p{
    font-size: 18px;
  }
}
.img-2{
  height: 65vh;
}
  .discription {
    h1{
      font-size: 20px;
    }
    p {
      font-size: 18px;
    }
  }
}
.process-III-section {
.col-lg-12 {
  .title-sec {
    h1 {
      font-size: 25px;
    }
  }
}
.text{
  padding-right: 0;
  h2{
    font-size: 20px;
  }
  p{
    font-size: 18px;
  }
}
.img-2{
  height: 345px;
  margin: auto;
}

}
.process-section{
.title{
  margin-bottom: 0;
  h1{
    font-size: 25px;
  }
}
  .part-1, .part-2, .part-3, .part-4, .part-5, .part-6, .part-7{
    .text-section{
      p{
        padding-right: 0;
      }
    }
  }
.text-section{
  padding-left: 0;
  h1{
    padding-right: 0;
    font-size: 20px;
  }
  p{
    padding-right: 0;
    font-size: 18px;
  }
}
.part6{
  margin-top: 0;
}
}
.process-footer-section{
.text-section {
  h2{
    font-size: 20px;
  }
  p {
    padding-right: 0;
    font-size: 18px;
  }
}
}
.bg-img{
height: 460px;
background-size: contain;
}
.HowItWorks_checkService__1fLi_{
padding: 20px 0;
.GooglePlaces_button__1A92p{
  margin: auto;
}
}
.ContactsNew_contacts_header__2OVVR {
margin-top: 30px;
font-size: 25px
}
.FAQ-section{
.faq-title{
  font-size: 25px;
}
}
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
.new-home-content{
height: 460px;
padding: 100px 0;
background-size: cover;
.main{
  .info-2{
    h1{
      font-size: 30px;
    }
    .info-1-new{
      font-size: 20px;
    }
    p{
      font-size:16px;
    }
  }
}

}
.after-prev{
.container{
  .bg-image{
    height: 400px;
  }
}
}
.process-I-section {
.head-title {
  .title {
    font-size: 30px;
  }
}
.text{
  padding-right: 0;
  h2{
    padding-right: 0;
    font-size: 25px;
  }
}
}
.process-II-section {
.img-2{
  height: 65vh;
}
.text{
  h2{
    font-size: 30px;
  }
}
}
.process-III-section {
.img-2{
  margin: auto;
}
.text{
  h2{
    font-size: 25px;
  }
}
.col-lg-12 {
  .title-sec {
    h1 {
      font-size: 30px;
    }
  }
}
}
.process-section{
.title{
  margin-bottom: 0;
  h1{
    font-size: 30px;
  }
}
.text-section{
  h1{
    font-size: 25px;
  }
}
.part6{
  margin-top: 0;
}
}
.process-footer-section{
.text{
  h2{
    font-size: 25px;
  }
}
}
.bg-img{
height: 560px;
background-size: contain;
}
.HowItWorks_checkService__1fLi_{
flex-direction: column;
padding: 20px 0;
.GooglePlaces_button__1A92p{
  margin: auto;
}
}
.ContactsNew_contacts_header__2OVVR {
margin-top: 30px;
font-size: 30px
}
.FAQ-section{
.faq-title{
  font-size: 30px;
}
}

  .badge-section {
    .badge-container-new {
      .badge-main{
        width: 600px;
        max-width: 600px;
      }
    }
  }
}
@media only screen and (min-width: 900px) and (max-width: 1039px) {
.new-home-content{
height: 550px;
padding: 140px 0;
background-size: cover;
.main{
  .info-2{
    h1{
      font-size: 35px;
    }
    .info-1-new{
      font-size: 20px;
    }
    p{
      font-size:16px;
    }
  }
}

}
.after-prev{
padding-top: 0;

.container{
  .bg-image{
    height: 470px;
  }
}
}
.process-I-section {
.text-l{
  padding-right: 0;
}
}
.process-II-section {
.img-2{
  height: 65vh;
}
}
.process-III-section {
.img-2{
  margin: auto;
}
}
.process-section{
.container{
  .row{
    .col-lg-12{
      .img-r-arrow, .img-l-arrow{
        height: 150px;
      }
      .img-l-arrow{
        margin-top: -99px;
        margin-bottom: -60px;
        margin-left: -30px;
      }
      .img-r-arrow{
        margin-top: -50px;
        margin-bottom: -60px;
      }
    }

  }

}
.part6{
  margin-top: 0;
}
}
.bg-img{
height: 625px;
background-size: contain;
}
.HowItWorks_checkService__1fLi_{
flex-direction: column;
padding: 20px 0;
.GooglePlaces_button__1A92p{
  margin: auto;
}
}
}
@media only screen and (min-width: 1040px) and (max-width: 1199px) {
  .Header_nav__link__2O5GH {
    padding: 8px 19px;
  }
.new-home-content{
height: 550px;
padding: 130px 0;
background-size: cover;
  .container{
    .title{
      padding: 0 55px;

    }
  }
}
.afterPrev{
padding-top: 0;
  .container{
    .bg-image{
      height: 400px;
      margin-top: 50px;
    }
  }
}
.process-I-section {
  .text{
    p{
      padding-right: 0;
    }
  }
.text-l{
  padding-right: 70px;
}
.image {
  height: 400px;
}
}
.process-II-section {
.img-2{
  height: 65vh;
}
  .discription{
    .p3{
      padding-right: 0;
    }
  }
  .text{
    padding-right: 0;
  }
}
.process-III-section {
.img-2{
  margin: auto;
}
  .text{
    padding-right: 0;
  }
}
.process-section{
.container{
  .row{
    .text-section {
      p{
        padding-right: 0;
      }
    }
    .part-1, .part-5, .part-6{
      h1{
        padding-right: 0;
      }
    }
    .part-3 {
      margin-top: 65px;
    }
    .part-4{
      h1{
        padding-right: 105px;
      }
    }
    .col-lg-12{
      .img-r-arrow, .img-l-arrow{
        height: 150px;
      }
      .img-l-arrow{
        margin-top: -99px;
        margin-bottom: -60px;
        margin-left: -30px;
      }
      .img-r-arrow{
        margin-top: -30px;
        margin-bottom: -50px;
      }

    }
    .img-4{
      margin-top: 0;
    }

    .part5{
      margin-top: 40px
    }
    .part6 {
      margin-top: 50px;
    }
    .part7{
      margin-top: 0;
      .img-7{
        margin-top: 0;
      }
      .text-section {
        padding-top: 65px;
      }
    }
    .part8{
      margin-top: 50px;
    }
  }
}
}
.process-footer-section{
.text-section{
  p{
    padding-right: 0;
  }
}
}
.bg-img{
height: 720px;
margin-top: -2px;
background-size: contain;
}
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
.new-home-content{
height: 705px;
padding: 180px 0 0;
}
.after-prev{
padding-top: 0;
.afterPrev{
  padding-top: 0;
}
}
.bg-img{
height: 840px;
margin-top: -2px;
background-size: contain;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
.new-home-content{
height: 805px;
padding: 190px 0 0;
}
.afterPrev{
margin-bottom: 50px;
}
.bg-img{
height: 960px;
margin-top: -2px;
background-size: contain;
}
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
.new-home-content{
height: 905px;
}
.afterPrev{
margin-bottom: 50px;
}
.bg-img{
height: 1080px;
background-size: contain;
}
}
@media only screen and (min-width: 1928px) and (max-width: 2000px) {
.new-home-content{
height: 1005px;
}
}

@media only screen and (min-width: 2000px){
.new-home-content{
height: 100vh;
background-size: cover;
}
.afterPrev{
margin-top: 100px;
}
.bg-img{
height: 100vh;
background-size: cover;
}
}