@import '../../scss/site-variables';

.modal {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  padding: 16px;
  background-color: $grey100;
}

.header {
  margin: 0;
}

.packages {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.packages_info {
  margin: 12px;
  text-align: center;
}

.package_price {
  margin-bottom: 2px;
}

.percent {
  min-height: 36px;
}

.package_itemDescription {
  line-height: 20px;
}

.table {
  display: grid;
  flex: 1 1 auto;
  margin-top: 12px;
  margin-bottom: 32px;
  column-gap: 12px;
  grid-template-columns: auto auto;
  overflow-y: auto;
  row-gap: 10px;
}

.package_icon {
  color: $grey600;
  font-size: 48px;
}

.package_icon___active {
  color: $accent500;
}

.button {
  flex: 1 0 auto;
  margin-bottom: 45px;
}
